import React from "react"
import { Link, useStaticQuery } from 'gatsby';
import Img from "gatsby-image";

const Testimony = ({testimony, count}) =>  {

    const isOdd = count % 2 == 0;
    
    return (
        <div className="px-4 pb-16 pt-16 container mx-auto mb-8 sm:flex">
            <div className="sm:flex">
                <div className="sm:w-1/2 mb-8 px-4 my-auto">
                    <p className="text-2xl mb-4 soft-gray">
                        {testimony.testimony.testimony}
                    </p>

                    <p className="font-aktiv font-bold text-xl soft-gray">{testimony.name}</p>
                    <p className="font-aktivc font-bold text-xs uppercase dark-blue">{testimony.position}</p>
                </div>

                <div className={`sm:w-1/2 sm:px-4 mb-6 sm:mr-6 sm:mb-0 ${isOdd && 'order-first'}`}>
                    <div className="mx-auto">
                        {testimony.picture &&
                            <Img className="object-cover object-center h-full block" fluid={testimony.picture.fluid} />
                        }
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Testimony;
